<template>
  <div>
    <validation-observer ref="createUser">
      <b-form
        class="mt-2 auth-create-form"
      >
        <b-row>
          <b-col cols="12" class="mb-2">
            <!-- <ImageDropzone 
              ref="images" 
              :value="model.myFile"
              @delete-image="avatarRemoved = true"
            /> -->
            <b-avatar size="8rem" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group :label="'Nombre*'" label-for="account-name">
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-input
                  @input="update('name', $event)"
                  :value="model.name"
                  name="name"
                  :placeholder="'Nombre'"
                />
                <small ref="errorField" class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="'Apellidos'" label-for="account-surname">
              <b-form-input
                @input="update('surname', $event)"
                :value="model.surname"
                :placeholder="'Apellidos'"
                name="surname"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="'Email'"
              :rules="mode === 'edit'? '' : 'required|email|emailUnique'"
            >
              <b-form-group :label="'Email*'" label-for="account-e-mail">
                <b-form-input
                  @input="update('email', $event)"
                  :value="model.email"
                  name="email"
                  :placeholder="'Email'"
                  :disabled="mode === 'edit'"
                />
                <small ref="errorField" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="mode !== 'edit'">
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="'Contraseña'"
              rules="required|min:8"
            >
              <b-form-group :label="'Contraseña*'" label-for="account-password">
                <b-input-group>
                  <template #append>
                    <b-input-group-text>
                      <div class="append-text" @click="show1 = !show1">
                        <span>
                          <feather-icon
                            :icon="show1 ? 'EyeIcon' : 'EyeOffIcon'"
                            :svg-classes="['text-white', 'h-5 w-5 mt-2']"
                          />
                        </span>
                      </div>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    @input="update('password', $event)"
                    :value="model.password"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    placeholder="Contraseña"
                    hint="At least 8 characters"
                    counter
                    outlined
                    class="w-full"
                  />
                </b-input-group>

                <small ref="errorField" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formMixin from "@/mixins/formMixin";
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'

export default {
  props: {
    mode: {
      type: String
    }
  },
  mixins: [formMixin],
  components: {
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      show1: false,
    };
  },
  methods: {
    handleFileInputChange(file) {
      this.massUpdate({ ...this.model, myFile: file, myFile: [file] })
    },
    validateForm() {
      // const file = this.$refs.images.getFormData()
      // this.handleFileInputChange(file)
      return this.$refs.createUser.validate();
    },
  },
};
</script>
